import React from "react";
import "../styles/InputField.css";

function InputField({ label, type, placeholder, value, onChange }) {
  return (
    <div className="input-field">
      <label className="input-label">{label}</label>
      <input
        className="input-box"
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
      />
    </div>
  );
}

export default InputField;
