import React, { Component } from "react";
import { Link } from "react-router-dom";
import TopBar from "../components/TopBar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCoffee,
  faUtensils,
  faDrumstickBite,
  faTrash,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { firebaseService } from "../services/firebaseService";
import { auth } from "../services/firebaseConfig";
import "../styles/MealPlanPage.css";
import AddDirectMealModal from "../components/AddDirectMealModal";

// Static data for the first letters of the days of the week and meal icons
const daysOfWeek = ["M", "T", "W", "T", "F", "S", "S"];
const mealIcons = {
  Breakfast: faCoffee,
  Lunch: faUtensils,
  Dinner: faDrumstickBite,
};

class MealPlanPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mealPlan: [], // Initialize with an empty meal plan
      showAddDirectMealModal: false,
      selectedDay: "",
    };
  }

  async componentDidMount() {
    try {
      const userId = auth.currentUser.uid;
      const mealPlanData = await firebaseService.getMealPlan(userId);

      // Directly use meal data from the meal plan without fetching details separately
      const mealPlanWithDetails = Object.keys(mealPlanData).map((key) => ({
        id: key, // Firebase generated key for the meal plan entry
        mealId: mealPlanData[key].mealId,
        day: mealPlanData[key].day,
        time: mealPlanData[key].time,
        name: mealPlanData[key].name || "Unknown Meal", // Use meal name if available
      }));

      this.setState({ mealPlan: mealPlanWithDetails });
    } catch (error) {
      console.error("Error fetching meal plan:", error);
    }
  }

  handleOpenAddDirectMealModal = (day) => {
    this.setState({ showAddDirectMealModal: true, selectedDay: day });
  };

  handleCloseAddDirectMealModal = () => {
    this.setState({ showAddDirectMealModal: false, selectedDay: "" });
  };

  handleAddDirectMeal = async (newMeal) => {
    try {
      const userId = auth.currentUser.uid;
      await firebaseService.addMealToPlan(
        userId,
        newMeal.mealId,
        newMeal.name, // Include the name parameter here
        newMeal.day,
        newMeal.time
      );

      // Update local state to reflect the new meal
      this.setState((prevState) => ({
        mealPlan: [...prevState.mealPlan, newMeal],
      }));
    } catch (error) {
      console.error("Error adding meal:", error);
    }
  };

  // Handle meal deletion
  deleteMeal = async (planId) => {
    try {
      const userId = auth.currentUser.uid;

      // Remove meal from plan for the user and paired users using the unique plan entry key
      await firebaseService.removeMealFromPlan(userId, planId);

      // Update state for the current user
      this.setState((prevState) => ({
        mealPlan: prevState.mealPlan.filter((meal) => meal.id !== planId),
      }));
    } catch (error) {
      console.error("Error deleting meal:", error);
    }
  };

  // Check if the add meal button should be disabled (if all 3 meal times are added)
  isAddMealDisabled = (day) => {
    const mealsForDay = this.getMealsForDay(day);
    const mealTimes = mealsForDay.map((meal) => meal.time);
    return (
      mealTimes.includes("Breakfast") &&
      mealTimes.includes("Lunch") &&
      mealTimes.includes("Dinner")
    );
  };

  getMealsForDay = (day) => {
    const mealsForDay = this.state.mealPlan.filter((meal) => meal.day === day);
    return mealsForDay.sort((a, b) => {
      const order = { Breakfast: 1, Lunch: 2, Dinner: 3 };
      return order[a.time] - order[b.time];
    });
  };

  parseIngredientString = (ingredientString) => {
    // Regular expression to match the quantity, unit, and ingredient name
    const quantityRegex =
      /^\s*(\d+\s?\d*\/?\d*|\d+(\.\d+)?|\d+\/\d+)?\s*(g|kg|ml|l|cas|cac|cuillère|pincée|tranche|boîte|sachet|tasse|bâtonnet)?\s*(.*)/i;

    const match = ingredientString.match(quantityRegex);
    if (!match) {
      return { name: ingredientString.trim(), quantity: 1 }; // Default to quantity 1 if parsing fails
    }

    let quantity = match[1] || "1"; // Default to "1" if no quantity is specified
    const unit = match[3] ? match[3].toLowerCase() : null;
    const name = match[4] ? match[4].trim() : "Unknown Ingredient";

    // Convert fraction to decimal if applicable
    if (quantity.includes("/")) {
      const fractionParts = quantity.split("/");
      if (fractionParts.length === 2) {
        quantity = parseFloat(fractionParts[0]) / parseFloat(fractionParts[1]);
      }
    } else {
      quantity = parseFloat(quantity);
    }

    return { name, quantity, unit };
  };

  generateGroceryList = async () => {
    try {
      const userId = auth.currentUser.uid;
      const { mealPlan } = this.state;

      let allIngredients = [];

      for (let meal of mealPlan) {
        const mealDetails = await firebaseService.getMealById(meal.mealId);
        if (mealDetails && mealDetails.ingredients) {
          // Collect ingredients as plain strings
          allIngredients.push(...mealDetails.ingredients);
        }
      }

      // Reset the current grocery list before adding new items
      await firebaseService.resetGroceryList(userId);

      // Save the ingredient strings directly to Firebase
      for (const ingredient of allIngredients) {
        await firebaseService.addItemToGroceryList(userId, ingredient);
      }

      console.log("Grocery list generated and saved to Firebase.");
      console.log(allIngredients);
    } catch (error) {
      console.error("Error generating grocery list:", error);
    }
  };

  // Simple parseIngredientString method
  parseIngredientString = (ingredientString) => {
    const regex = /^(\d*\.?\d*)?\s*(.*?)(?:\s*\((.*?)\))?$/;
    const match = ingredientString.match(regex);

    if (match) {
      const quantity = match[1] ? parseFloat(match[1]) : null;
      const name = match[2]?.trim() || null;
      const unit = match[3]?.trim() || null;

      return { name, quantity, unit };
    } else {
      console.warn(`Could not parse ingredient: ${ingredientString}`);
      return { name: ingredientString.trim(), quantity: null, unit: null };
    }
  };

  renderMealPlan = () => {
    return (
      <div className="meal-plan-container">
        {daysOfWeek.map((day, index) => (
          <div className="meal-plan-row" key={day}>
            <div className="day-label">
              <span>{day}</span>
            </div>
            <div className="meal-plan-chips">
              {this.getMealsForDay(this.getFullDayName(index)).map((meal) => (
                <div
                  key={meal.id}
                  className={`meal-chip ${meal.time.toLowerCase()}-chip`}
                >
                  <FontAwesomeIcon
                    icon={mealIcons[meal.time]}
                    className="meal-icon"
                  />
                  <span className="meal-name">{meal.name}</span>
                  {/* Delete button */}
                  <FontAwesomeIcon
                    icon={faTrash}
                    className="delete-icon"
                    onClick={() => this.deleteMeal(meal.id)}
                  />
                </div>
              ))}
            </div>
            {/* Add meal button in top-right corner */}
            <button
              className="add-meal-button"
              onClick={() =>
                this.handleOpenAddDirectMealModal(this.getFullDayName(index))
              }
              disabled={this.isAddMealDisabled(this.getFullDayName(index))}
            >
              <FontAwesomeIcon icon={faPlus} />
            </button>
          </div>
        ))}
      </div>
    );
  };

  getFullDayName = (index) => {
    const fullDayNames = [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday",
    ];
    return fullDayNames[index];
  };

  render() {
    return (
      <div className="meal-plan-page">
        <TopBar title="Weekly Meal Plan" />
        {this.renderMealPlan()}

        <AddDirectMealModal
          open={this.state.showAddDirectMealModal}
          onClose={this.handleCloseAddDirectMealModal}
          onAddMeal={this.handleAddDirectMeal}
          day={this.state.selectedDay}
        />

        {/* Generate Grocery List Button */}
        <div className="generate-grocery-list-container">
          <Link to="/grocery-list">
            <button
              className="generate-grocery-list-button"
              onClick={this.generateGroceryList}
            >
              Generate Grocery List
            </button>
          </Link>
        </div>
      </div>
    );
  }
}

export default MealPlanPage;
