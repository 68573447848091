import React, { useState, useEffect } from "react";
import {
  Typography,
  Box,
  Container,
  Grid,
  Menu,
  MenuItem,
  ToggleButton,
  ToggleButtonGroup,
  CircularProgress,
} from "@mui/material";
import { firebaseService } from "../services/firebaseService";
import { auth } from "../services/firebaseConfig";
import TopBar from "../components/TopBar";
import SwipeCard from "../components/SwipeCard";
import SentimentDissatisfiedIcon from "@mui/icons-material/SentimentDissatisfied";

import NoMatchesImage from "../assets/no_matches_image.png";

import { useLikedMeals } from "../contexts/LikedMealsContext";

import { ReactComponent as QuestionIcon } from "../assets/question-mark-icon.svg";
import { ReactComponent as HeartIcon } from "../assets/favorite-icon.svg";
import { ReactComponent as StarIcon } from "../assets/star-icon.svg";

import "../styles/LikedMealsPage.css";

function LikedMealsPage() {
  const { likedMeals, setLikedMeals, loading } = useLikedMeals();
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedSwipe, setSelectedSwipe] = useState(null);
  const [filter, setFilter] = useState("All");

  const userId = auth.currentUser ? auth.currentUser.uid : null;

  // Use useEffect to fetch the liked meals whenever the userId or context updates
  useEffect(() => {
    if (userId) {
      firebaseService.getLikedMeals(userId, setLikedMeals);
    }
  }, [userId, setLikedMeals]); // Listen for changes to userId or setLikedMeals

  const handleUnlike = async (mealId, swipeId) => {
    if (userId) {
      const success = await firebaseService.unlikeMeal(userId, mealId);
      if (success) {
        setLikedMeals((prevMeals) =>
          prevMeals.filter((swipe) => swipe.swipeId !== swipeId)
        );
      } else {
        console.error("Failed to unmatch meal");
      }
    }
  };

  const handleRatingClick = (event, swipe) => {
    setAnchorEl(event.currentTarget);
    setSelectedSwipe(swipe);
  };

  const handleRatingClose = () => {
    setAnchorEl(null);
    setSelectedSwipe(null);
  };

  const handleRatingChange = async (newRatingType) => {
    if (userId && selectedSwipe) {
      await firebaseService.changeRatingType(
        userId,
        selectedSwipe.mealId,
        newRatingType
      );
      setLikedMeals((prevSwipes) =>
        prevSwipes.map((swipe) =>
          swipe.swipeId === selectedSwipe.swipeId
            ? { ...swipe, ratingType: newRatingType }
            : swipe
        )
      );
    }
    handleRatingClose();
  };

  const getLikeTypeIcon = (ratingType) => {
    switch (ratingType) {
      case "Why not":
        return <QuestionIcon className="custom-icon" />;
      case "Like":
        return <HeartIcon className="custom-icon" />;
      case "Best":
        return <StarIcon className="custom-icon" />;
      default:
        return null;
    }
  };

  const handleFilterChange = (event, newFilter) => {
    if (newFilter !== null) {
      setFilter(newFilter);
    }
  };

  const filteredSwipes = likedMeals.filter((swipe) =>
    filter === "All" ? true : swipe.ratingType === filter
  );

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <div className="likes-page">
      <TopBar title="Likes" />
      <Container maxWidth="md" sx={{ flexGrow: 1, py: 3 }}>
        <ToggleButtonGroup
          value={filter}
          exclusive
          onChange={handleFilterChange}
          sx={{
            mb: 3,
            "& .MuiToggleButtonGroup-grouped": {
              bgcolor: "#FFAAAA",
              color: "#474973",
              width: "50px",
              height: "50px",
              "&.Mui-selected": {
                bgcolor: "#FFAAAA",
                color: "#f5f5f5",
              },
              "&:not(:first-of-type)": {
                borderRadius: "25px",
              },
              "&:not(:last-of-type)": {
                borderRadius: "25px",
              },
            },
          }}
        >
          <ToggleButton value="All" sx={{ borderRadius: 25, mr: 2 }}>
            All
          </ToggleButton>
          <ToggleButton value="Why not" sx={{ borderRadius: 25, mr: 2 }}>
            <QuestionIcon className="custom-icon" />
          </ToggleButton>
          <ToggleButton value="Like" sx={{ borderRadius: 25, mr: 2 }}>
            <HeartIcon className="custom-icon" />
          </ToggleButton>
          <ToggleButton value="Best" sx={{ borderRadius: 25 }}>
            <StarIcon className="custom-icon" />
          </ToggleButton>
        </ToggleButtonGroup>

        {filteredSwipes.length > 0 ? (
          <Grid container spacing={2}>
            {filteredSwipes.map((swipe) => (
              <Grid item xs={6} key={swipe.swipeId}>
                <SwipeCard
                  mealId={swipe.mealId}
                  ratingType={swipe.ratingType}
                  getLikeTypeIcon={getLikeTypeIcon}
                  onUnlike={() => handleUnlike(swipe.mealId, swipe.swipeId)}
                  showUnlikeButton={true}
                  onRatingClick={(event) => handleRatingClick(event, swipe)}
                />
              </Grid>
            ))}
          </Grid>
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
              textAlign: "center",
            }}
          >
            <SentimentDissatisfiedIcon
              sx={{ fontSize: 80, color: "#474973" }}
            />
            <Typography variant="h3" sx={{ mt: 2, color: "#474973" }}>
              No likes here yet...
            </Typography>
            <img
              src={NoMatchesImage}
              alt="No likes"
              style={{ marginTop: "20px", maxWidth: "50%", height: "auto" }}
            />
          </Box>
        )}
      </Container>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleRatingClose}
      >
        <MenuItem onClick={() => handleRatingChange("Why not")}>
          Why not
        </MenuItem>
        <MenuItem onClick={() => handleRatingChange("Like")}>Like</MenuItem>
        <MenuItem onClick={() => handleRatingChange("Best")}>Best</MenuItem>
      </Menu>
    </div>
  );
}

export default LikedMealsPage;
