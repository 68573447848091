import React, { useState, useEffect } from "react";
import {
  Typography,
  Box,
  TextField,
  Button,
  Grid,
  Container,
} from "@mui/material";
import { auth, db } from "../services/firebaseConfig";
import { ref, get, update } from "firebase/database";
import { useAuthState } from "react-firebase-hooks/auth";
import TopBar from "../components/TopBar";

function ProfilePage() {
  const [user] = useAuthState(auth);
  const [nickname, setNickname] = useState("");
  const [email, setEmail] = useState("");

  useEffect(() => {
    if (user) {
      const userRef = ref(db, `users/${user.uid}`);
      get(userRef).then((snapshot) => {
        if (snapshot.exists()) {
          const data = snapshot.val();
          setNickname(data.nickname || "");
          setEmail(user.email);
        }
      });
    }
  }, [user]);

  const handleUpdateNickname = async () => {
    const userRef = ref(db, `users/${user.uid}`);
    await update(userRef, { nickname });
    alert("Nickname updated successfully!");
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "92vh",
        width: "100%",
        padding: "5px",
        bgcolor: "#f5f5f5", // White smoke
      }}
    >
      <TopBar title="Profile" />
      <Container
        maxWidth="sm"
        sx={{
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          pt: 2,
          px: 2,
        }}
      >
        <Grid container spacing={3} alignItems="center" justifyContent="center">
          <Grid item xs={12}></Grid>
          <Grid item xs={12}>
            <Typography variant="body1" align="left" sx={{ color: "#474973" }}>
              {email}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Nickname"
              value={nickname}
              onChange={(e) => setNickname(e.target.value)}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderRadius: 25,
                    borderColor: "#474973", // Ultra Violet
                  },
                  "&:hover fieldset": {
                    borderColor: "#474973", // Ultra Violet
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#474973", // Ultra Violet
                  },
                },
                "& .MuiInputLabel-root": {
                  color: "#474973", // Ultra Violet
                },
                "& .MuiInputBase-input": {
                  color: "#474973", // Ultra Violet
                },
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              fullWidth
              variant="contained"
              sx={{
                bgcolor: "#f45f67", // Bright pink (Crayola)
                color: "#f5f5f5", // White smoke
                borderRadius: 25,
                mt: 3,
                "&:hover": {
                  bgcolor: "#ef1e28", // Darker pink
                },
              }}
              onClick={handleUpdateNickname}
            >
              Update Nickname
            </Button>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default ProfilePage;
