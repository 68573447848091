import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Typography,
  Box,
  Container,
  List,
  ListItem,
  ListItemText,
  Card,
  CardMedia,
  CardContent,
  Button,
  IconButton,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import FastfoodIcon from "@mui/icons-material/Fastfood";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import LocalDiningIcon from "@mui/icons-material/LocalDining";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import LinkIcon from "@mui/icons-material/Link";
import { firebaseService } from "../services/firebaseService"; // Ensure the correct path
import { NoEncryption } from "@mui/icons-material";

const MealPage = () => {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [meal, setMeal] = useState(null);

  useEffect(() => {
    const fetchMeal = async () => {
      const mealData = await firebaseService.getMealById(id);
      if (mealData) {
        setMeal(mealData);
      } else {
        navigate("/"); // If meal data is not available, navigate to the home page
      }
    };

    if (!meal) {
      fetchMeal();
    }
  }, [id, navigate]);

  if (!meal) {
    return (
      <Container maxWidth="md" sx={{ mt: 4, bgcolor: "white", p: 3 }}>
        <Typography variant="h6" sx={{ color: "#555" }}>
          Loading...
        </Typography>
      </Container>
    );
  }

  return (
    <Container maxWidth="md" sx={{ mt: 4, p: 0 }}>
      <Card sx={{ bgcolor: "white", boxShadow: "none" }}>
        <Button
          variant="contained"
          onClick={() => navigate(-1)}
          startIcon={
            <ArrowBackIcon sx={{ fontSize: "100px", fontWeight: "bold" }} /> // Increase size and weight
          }
          sx={{
            width: "48px", // Make the button circular
            height: "48px",
            minWidth: "48px", // Ensure the button doesn't stretch horizontally
            bgcolor: "#ffa69e",
            color: "white",
            borderRadius: "50%", // Make the button round
            boxShadow: "none",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mb: 2,
            ml: 2,
          }}
        ></Button>

        <CardMedia
          component="img"
          height="auto"
          image={meal.img_link}
          alt={meal.title}
          sx={{ width: "100%", maxHeight: "500px", objectFit: "cover" }}
        />
        <CardContent>
          {/* Pink rounded box for meal title and details */}
          <Box
            sx={{
              bgcolor: "#ffa69e",
              p: 2,
              borderRadius: "50px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              textAlign: "center",
              mb: 2,
            }}
          >
            {/* Meal title */}
            <Typography
              variant="h5"
              component="div"
              sx={{
                fontFamily: "Alfa Slab One",
                fontSize: "1.5em",
                fontWeight: "bold",
                color: "white",
              }}
            >
              {meal.title}
            </Typography>

            {/* Nutritional details row */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-around",
                width: "100%",
                mt: 1,
                color: "white",
              }}
            >
              <Box sx={{ textAlign: "center" }}>
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: "bold",
                    mb: 0,
                    lineHeight: 0.5,
                    fontFamily: "Alfa Slab One",
                  }}
                >
                  {meal.nutrition?.["Calories"]?.split(" ")[0] || "N/A"}
                </Typography>
                <Typography variant="caption" sx={{ lineHeight: 1 }}>
                  kcals
                </Typography>
              </Box>

              <Box sx={{ textAlign: "center" }}>
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: "bold",
                    mb: 0,
                    lineHeight: 0.5,
                    fontFamily: "Alfa Slab One",
                  }}
                >
                  {meal.scores?.["Nutri-score"]?.split(" ")[1] || "N/A"}
                </Typography>
                <Typography variant="caption" sx={{ lineHeight: 1 }}>
                  nutri
                </Typography>
              </Box>

              <Box sx={{ textAlign: "center" }}>
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: "bold",
                    mb: 0,
                    lineHeight: 0.5,
                    fontFamily: "Alfa Slab One",
                  }}
                >
                  {meal.scores?.["Eco-score"]?.split(" ")[1] || "N/A"}
                </Typography>
                <Typography variant="caption" sx={{ lineHeight: 1 }}>
                  eco
                </Typography>
              </Box>

              <Box
                sx={{
                  textAlign: "center",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Typography variant="caption" sx={{ lineHeight: 1 }}>
                  {meal.difficulty || "N/A"}
                </Typography>
              </Box>
            </Box>
          </Box>
          {/* Nutrition Box */}
          <Box
            sx={{
              bgcolor: "#faf3dd",
              borderRadius: "50px",
              p: 2,
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
              mt: 2,
            }}
          >
            {meal.nutrition ? (
              <>
                <Box sx={{ textAlign: "center" }}>
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: "bold",
                      color: "#1b1919",
                      lineHeight: 0.5,
                      fontFamily: "Alfa Slab One",
                    }}
                  >
                    {meal.nutrition["Fibres"] || "N/A"}
                  </Typography>
                  <Typography
                    variant="caption"
                    sx={{ lineHeight: 1, color: "#1b1919" }}
                  >
                    fibers
                  </Typography>
                </Box>

                <Box sx={{ textAlign: "center" }}>
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: "bold",
                      color: "#1b1919",
                      lineHeight: 0.5,
                      fontFamily: "Alfa Slab One",
                    }}
                  >
                    {meal.nutrition["Glucides"] || "N/A"}
                  </Typography>
                  <Typography
                    variant="caption"
                    sx={{ lineHeight: 1, color: "#1b1919" }}
                  >
                    carbs
                  </Typography>
                </Box>

                <Box sx={{ textAlign: "center" }}>
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: "bold",
                      color: "#1b1919",
                      lineHeight: 0.5,
                      fontFamily: "Alfa Slab One",
                    }}
                  >
                    {meal.nutrition["Matières grasses"] || "N/A"}
                  </Typography>
                  <Typography
                    variant="caption"
                    sx={{ lineHeight: 1, color: "#1b1919" }}
                  >
                    fat
                  </Typography>
                </Box>

                <Box sx={{ textAlign: "center" }}>
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: "bold",
                      color: "#1b1919",
                      lineHeight: 0.5,
                      fontFamily: "Alfa Slab One",
                    }}
                  >
                    {meal.nutrition["Protéines"] || "N/A"}
                  </Typography>
                  <Typography
                    variant="caption"
                    sx={{ lineHeight: 1, color: "#1b1919" }}
                  >
                    prots
                  </Typography>
                </Box>
              </>
            ) : (
              <Typography variant="body2" sx={{ color: "#777" }}>
                N/A
              </Typography>
            )}
          </Box>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns:
                meal.ingredients && meal.ingredients.length > 6
                  ? "1fr 1fr"
                  : "1fr",
              gap: 1,
              bgcolor: "#faf3dd",
              borderRadius: "20px",
              p: 2,
              mt: 2,
            }}
          >
            <Typography
              variant="body1"
              gutterBottom
              sx={{
                fontWeight: "bold",
                color: "#1b1919",
                lineHeight: 0.5,
                fontFamily: "Alfa Slab One",
              }}
            >
              Ingredients:
            </Typography>
            {meal.ingredients ? (
              meal.ingredients.map((ingredient, index) => (
                <Box
                  key={index}
                  sx={{
                    textAlign: "left",
                    lineHeight: 0.5,
                    padding: "8px",
                    color: "#777",
                  }}
                >
                  {ingredient}
                </Box>
              ))
            ) : (
              <Typography variant="body2" sx={{ color: "#777" }}>
                N/A
              </Typography>
            )}
          </Box>

          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "1fr",
              gap: 1,
              bgcolor: "#faf3dd",
              borderRadius: "20px",
              p: 2,
              mt: 2,
            }}
          >
            <Typography
              variant="body1"
              gutterBottom
              sx={{
                fontWeight: "bold",
                color: "#1b1919",
                lineHeight: 0.5,
                fontFamily: "Alfa Slab One",
              }}
            >
              Steps:
            </Typography>

            {meal.steps ? (
              meal.steps.map((step, index) => (
                <Box
                  key={index}
                  sx={{
                    textAlign: "left",
                    color: "#777",
                    padding: "4px 8px",
                  }}
                >
                  {`${index + 1}. ${step}`}
                </Box>
              ))
            ) : (
              <Typography variant="body2" sx={{ color: "#777" }}>
                N/A
              </Typography>
            )}
          </Box>

          <Typography variant="body1" gutterBottom>
            <IconButton
              component="a"
              href={meal.link}
              target="_blank"
              rel="noopener noreferrer"
              sx={{ color: "#1e88e5" }}
            >
              <LinkIcon />
              View Full Recipe
            </IconButton>
          </Typography>
        </CardContent>
      </Card>
    </Container>
  );
};

export default MealPage;
