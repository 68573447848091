// src/components/InfoButton.js
import React from "react";
import { IconButton } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import "../styles/InfoButton.css";

const InfoButton = ({ onClick }) => {
  return (
    <IconButton onClick={onClick} className="info-icon-button" size="small">
      <InfoIcon />
    </IconButton>
  );
};

export default InfoButton;
