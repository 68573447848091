import React, { useState, useEffect } from "react";
import TopBar from "../components/TopBar";
import MatchCard from "../components/MatchCard";
import MatchCardAlt from "../components/MatchCardAlt";
import { firebaseService } from "../services/firebaseService";
import { auth } from "../services/firebaseConfig";
import SentimentDissatisfiedIcon from "@mui/icons-material/SentimentDissatisfied";
import NoMatchesImage from "../assets/no_matches_image.png";

import "../styles/MatchesPage.css";
import AddMealModal from "../components/AddMealModal";

const MatchesPage = () => {
  const [superMatches, setSuperMatches] = useState([]);
  const [regularMatches, setRegularMatches] = useState([]);
  const [filter, setFilter] = useState("superMatches");
  const [selectedMeal, setSelectedMeal] = useState(null);
  const [showAddMealModal, setShowAddMealModal] = useState(false);
  const [randomSuperMatchIndex, setRandomSuperMatchIndex] = useState(null);
  const [randomRegularMatchIndex, setRandomRegularMatchIndex] = useState(null);

  // New state for random meals
  const [randomMeals, setRandomMeals] = useState([]);
  const [showRandomMeals, setShowRandomMeals] = useState(false);

  useEffect(() => {
    const userId = auth.currentUser.uid;
    const fetchMatches = async () => {
      try {
        await firebaseService.getMatches(
          setSuperMatches,
          setRegularMatches,
          userId
        );
      } catch (error) {
        console.error("Error fetching matches:", error);
      }
    };
    fetchMatches();
  }, []);

  // Generate random index for superMatches after they are set
  useEffect(() => {
    if (superMatches.length > 0) {
      setRandomSuperMatchIndex(Math.floor(Math.random() * superMatches.length));
    }
  }, [superMatches]);

  // Generate random index for regularMatches after they are set
  useEffect(() => {
    if (regularMatches.length > 0) {
      setRandomRegularMatchIndex(
        Math.floor(Math.random() * regularMatches.length)
      );
    }
  }, [regularMatches]);

  useEffect(() => {
    if (showRandomMeals) {
      generateRandomMeals(); // Only generates random meals when `showRandomMeals` is true
    }
  }, [showRandomMeals]);

  const handleFilterChange = (newFilter) => {
    setFilter(newFilter);
    setShowRandomMeals(false); // Always reset `showRandomMeals`

    // Only generate random meals when switching to the "random" filter
    if (newFilter === "random") {
      setRandomMeals([]); // Clear the previous random meals
      generateRandomMeals(); // Generate a fresh list of 4 random meals
      setShowRandomMeals(true); // Set flag to show the random meals
    } else {
      setRandomMeals([]); // Ensure randomMeals is empty when not on the random filter
    }
  };

  const handleUnlike = async (mealId) => {
    const success = await firebaseService.unlikeMeal(
      auth.currentUser.uid,
      mealId
    );
    if (success) {
      setSuperMatches((prevMatches) =>
        prevMatches.filter((match) => match.mealId !== mealId)
      );
      setRegularMatches((prevMatches) =>
        prevMatches.filter((match) => match.mealId !== mealId)
      );
    } else {
      console.error("Failed to unmatch meal");
    }
  };

  const handleAddToMealPlan = (meal) => {
    setSelectedMeal(meal);
    setShowAddMealModal(true);
  };

  const handleAddMeal = async (newMeal) => {
    try {
      const userId = auth.currentUser.uid;

      // Add the meal to the plan in Firebase
      await firebaseService.addMealToPlan(
        userId,
        newMeal.mealId,
        newMeal.name,
        newMeal.day,
        newMeal.time
      );

      console.log("Meal successfully added to plan:", newMeal);
    } catch (error) {
      console.error("Error adding meal to plan:", error);
    }
  };

  const generateRandomMeals = () => {
    const allMatches = [...superMatches, ...regularMatches];
    if (allMatches.length === 0) {
      setRandomMeals([]); // If no matches, set empty
    } else {
      const shuffled = allMatches.sort(() => 0.5 - Math.random());
      setRandomMeals(shuffled.slice(0, 4)); // Select exactly 4 unique meals
    }
  };

  // Re-renders the match cards with alternating MatchCard and MatchCardAlt
  const renderMatches = () => {
    // Use filtered matches directly based on the filter state
    const filteredMatches =
      filter === "superMatches" ? superMatches : regularMatches;

    return (
      <>
        {filteredMatches.length > 0 ? (
          <div className="match-grid-container">
            {filteredMatches.map((match, index) => (
              <div className="grid-match-item" key={match.mealId}>
                {index % 2 === 0 ? (
                  <MatchCard
                    match={match}
                    onUnlike={() => handleUnlike(match.mealId)}
                    showUnlikeButton={true}
                    onAddToMealPlan={handleAddToMealPlan}
                  />
                ) : (
                  <MatchCardAlt
                    match={match}
                    onUnlike={() => handleUnlike(match.mealId)}
                    showUnlikeButton={true}
                    onAddToMealPlan={handleAddToMealPlan}
                  />
                )}
              </div>
            ))}
          </div>
        ) : (
          <div className="no-matches">
            <SentimentDissatisfiedIcon className="no-matches-icon" />
            <div className="no-matches-text">No matches here yet...</div>
            <img
              src={NoMatchesImage}
              alt="No matches"
              className="no-matches-image"
            />
          </div>
        )}
      </>
    );
  };

  // Render the random meals section with MatchCard and MatchCardAlt
  const renderRandomMeals = () => (
    <>
      {randomMeals.length > 0 ? (
        <div className="match-grid-container">
          {randomMeals.map((meal, index) => (
            <div className="grid-match-item" key={meal.mealId}>
              {index % 2 === 0 ? (
                <MatchCard
                  match={meal}
                  onUnlike={() => handleUnlike(meal.mealId)}
                  showUnlikeButton={true}
                  onAddToMealPlan={handleAddToMealPlan}
                />
              ) : (
                <MatchCardAlt
                  match={meal}
                  onUnlike={() => handleUnlike(meal.mealId)}
                  showUnlikeButton={true}
                  onAddToMealPlan={handleAddToMealPlan}
                />
              )}
            </div>
          ))}
        </div>
      ) : (
        <div>No random meals available</div>
      )}
    </>
  );

  return (
    <div className="matches-page" key={filter}>
      {/* This key forces a re-render when the filter changes */}
      <TopBar title="Matches" />
      <div className="toggle-button-group">
        <div className="toggle-button-container">
          <button
            className={`toggle-button ${
              filter === "superMatches" ? "active" : ""
            }`}
            onClick={() => {
              handleFilterChange("superMatches");
              setShowRandomMeals(false); // Hide random meals when switching filters
            }}
          >
            {superMatches.length > 1 && randomSuperMatchIndex !== null ? (
              <div className="meal-stack">
                {/* First (top) meal */}
                <img
                  src={superMatches[randomSuperMatchIndex].imageUrl}
                  alt="Super Match Meal"
                  className="meal-image-top"
                />
                {/* Second (bottom) meal */}
                <img
                  src={
                    superMatches[
                      (randomSuperMatchIndex + 1) % superMatches.length
                    ].imageUrl
                  }
                  alt="Super Match Meal"
                  className="meal-image-bottom"
                />
              </div>
            ) : (
              <div className="meal-display-text">No Super Matches</div>
            )}
          </button>
          <div
            className="match-filter-text"
            style={{
              fontWeight: filter === "superMatches" ? "bold" : "normal",
            }}
          >
            Must
          </div>
        </div>

        <div className="toggle-button-container">
          <button
            className={`toggle-button ${
              filter === "regularMatches" ? "active" : ""
            }`}
            onClick={() => {
              handleFilterChange("regularMatches");
              setShowRandomMeals(false); // Hide random meals when switching filters
            }}
          >
            {regularMatches.length > 1 && randomRegularMatchIndex !== null ? (
              <div className="meal-stack">
                {/* First (top) meal */}
                <img
                  src={regularMatches[randomRegularMatchIndex].imageUrl}
                  alt="Regular Match Meal"
                  className="meal-image-top"
                />
                {/* Second (bottom) meal */}
                <img
                  src={
                    regularMatches[
                      (randomRegularMatchIndex + 1) % regularMatches.length
                    ].imageUrl
                  }
                  alt="Regular Match Meal"
                  className="meal-image-bottom"
                />
              </div>
            ) : (
              <div className="meal-display-text">No Matches</div>
            )}
          </button>
          <div
            className="match-filter-text"
            style={{
              fontWeight: filter === "regularMatches" ? "bold" : "normal",
            }}
          >
            Matches
          </div>
        </div>

        <div className="toggle-button-container">
          <button
            className="toggle-button"
            onClick={() => {
              if (!showRandomMeals) {
                generateRandomMeals();
                setShowRandomMeals(true);
              }
            }}
          >
            Random
          </button>
          <div className="match-filter-text">Random</div>
        </div>
      </div>

      {/* Show random meals if the flag is true */}
      {showRandomMeals ? renderRandomMeals() : renderMatches()}

      <AddMealModal
        open={showAddMealModal}
        onClose={() => setShowAddMealModal(false)}
        meal={selectedMeal}
        onAddMeal={handleAddMeal}
      />
    </div>
  );
};

export default MatchesPage;
