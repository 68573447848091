import React, { useState, useEffect } from "react";
import {
  Typography,
  Switch,
  Button,
  Box,
  TextField,
  Grid,
  Container,
} from "@mui/material";
import { auth, db } from "../services/firebaseConfig";
import { ref, get, update } from "firebase/database";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import LogoutButton from "../components/LogoutButton";
import TopBar from "../components/TopBar";
import "../styles/SettingsPage.css"; // Import the CSS file

function SettingsPage() {
  const [user] = useAuthState(auth);
  const [notifications, setNotifications] = useState(false);
  const [darkMode, setDarkMode] = useState(false);
  const [nickname, setNickname] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      const userRef = ref(db, `users/${user.uid}`);
      get(userRef).then((snapshot) => {
        if (snapshot.exists()) {
          const data = snapshot.val();
          setNotifications(data.notifications || false);
          setDarkMode(data.darkMode || false);
          setNickname(data.nickname || "");
        }
      });
    }
  }, [user]);

  const handleSaveSettings = async () => {
    const userRef = ref(db, `users/${user.uid}`);
    await update(userRef, { notifications, darkMode, nickname });
  };

  return (
    <div className="settings-page">
      <TopBar title="Settings" />
      <Container maxWidth="sm" className="settings-container">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Button
              fullWidth
              variant="outlined"
              className="outlined-button"
              onClick={() => navigate("/profile")}
            >
              View Profile
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button
              fullWidth
              variant="outlined"
              className="outlined-button"
              onClick={() => navigate("/pairing-management")}
            >
              Manage Pairings
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button
              fullWidth
              variant="outlined"
              className="outlined-button"
              onClick={() => navigate("/dietary-preferences")}
            >
              Update Dietary Preferences
            </Button>
          </Grid>
          <Grid item xs={12}>
            <LogoutButton />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default SettingsPage;
