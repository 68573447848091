import React, { useState, useEffect, useContext } from "react";
import { Box, Container } from "@mui/material";
import { firebaseService } from "../services/firebaseService";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../services/firebaseConfig";
import { useNavigate } from "react-router-dom";
import { SwipeContext } from "../contexts/SwipeContext";
import TopBar from "../components/TopBar";
import MealTitle from "../components/MealTitle";
import InfoButton from "../components/InfoButton";
import RatingButtons from "../components/RatingButtons";
import MatchNotification from "../components/MatchNotification"; // Import the custom component
import "../styles/SwipePage.css";

function SwipePage() {
  const { meals, setMeals, currentMealIndex, setCurrentMealIndex } =
    useContext(SwipeContext);
  const [matchNotification, setMatchNotification] = useState(null);
  const [userId, setUserId] = useState(null);
  const [lastMealKey, setLastMealKey] = useState(null);
  const [noMoreMeals, setNoMoreMeals] = useState(false);
  const MEALS_LIMIT = 50;
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserId(user.uid);
        fetchMeals(user.uid);
      }
    });

    return () => unsubscribe();
  }, []);

  const fetchMeals = async (userId) => {
    const lastKey = await firebaseService.getMeals(
      userId,
      setMeals,
      MEALS_LIMIT,
      lastMealKey
    );
    setLastMealKey(lastKey);
    setNoMoreMeals(!lastKey);
  };

  const handleRating = async (rating) => {
    if (meals.length === 0 || !userId) return;

    const currentMeal = meals[currentMealIndex];
    const match = await firebaseService.recordRating(
      userId,
      currentMeal.id,
      rating
    );

    if (rating !== "Dislike" && match) {
      setMatchNotification({ meal: currentMeal, message: `It's a match!` });
    }

    const nextIndex = currentMealIndex + 1;
    if (nextIndex >= meals.length) {
      const lastKey = await firebaseService.getMeals(
        userId,
        setMeals,
        MEALS_LIMIT,
        lastMealKey
      );
      setLastMealKey(lastKey);
      setCurrentMealIndex(0);
      setNoMoreMeals(!lastKey);
    } else {
      setCurrentMealIndex(nextIndex);
    }
  };

  const handleCloseNotification = () => {
    setMatchNotification(null);
  };

  const currentMeal = meals[currentMealIndex];

  return (
    <div className="swipe-page">
      <TopBar title="Matchas" />
      {meals.length === 0 && noMoreMeals ? (
        <Container className="no-more-meals">
          <div className="no-more-meals-text">
            No more meals available. Please check back later!
          </div>
        </Container>
      ) : (
        <div className="meal-container">
          <div className="meal-image-container">
            <img
              className="meal-image"
              src={currentMeal?.img_link}
              alt={currentMeal?.title}
            />
          </div>
          <div className="meal-info">
            <MealTitle title={currentMeal?.title} />
            <InfoButton
              onClick={() =>
                navigate(`/meal/${currentMeal.id}`, {
                  state: { meal: currentMeal },
                })
              }
            />
          </div>
          <div className="ratings-container">
            <RatingButtons handleRating={handleRating} />
          </div>
        </div>
      )}
      <MatchNotification
        open={!!matchNotification}
        onClose={handleCloseNotification}
        meal={matchNotification?.meal}
        message={matchNotification?.message}
      />
    </div>
  );
}

export default SwipePage;
