import React, { useState, useEffect } from "react";
import {
  Modal,
  Box,
  Typography,
  FormControl,
  Select,
  MenuItem,
  TextField,
  Button,
} from "@mui/material";
import { firebaseService } from "../services/firebaseService";
import { auth } from "../services/firebaseConfig";

const AddDirectMealModal = ({ open, onClose, onAddMeal, day }) => {
  const [matchedMeals, setMatchedMeals] = useState([]);
  const [selectedMealId, setSelectedMealId] = useState("");
  const [customMealName, setCustomMealName] = useState("");
  const [selectedTime, setSelectedTime] = useState("");

  useEffect(() => {
    // Fetch matched meals for selection
    const fetchMatches = async () => {
      try {
        const userId = auth.currentUser.uid;
        const matches = await firebaseService.getUserMatches(userId);
        setMatchedMeals(
          matches.map((match) => ({
            id: match.mealId,
            name: match.name,
          }))
        );
      } catch (error) {
        console.error("Error fetching matched meals:", error);
      }
    };
    fetchMatches();
  }, []);

  const handleAddMeal = () => {
    const mealName = selectedMealId
      ? matchedMeals.find((meal) => meal.id === selectedMealId).name
      : customMealName;

    if (mealName && selectedTime) {
      const newMeal = {
        mealId: selectedMealId ? selectedMealId : Date.now().toString(), // Use selected meal ID or generate a new one for custom meal
        name: mealName,
        day,
        time: selectedTime,
      };
      onAddMeal(newMeal);
      handleClose();
    }
  };

  const handleClose = () => {
    setSelectedMealId("");
    setCustomMealName("");
    setSelectedTime("");
    onClose();
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          p: 4,
          borderRadius: 2,
          boxShadow: 24,
        }}
      >
        <Typography variant="h6" gutterBottom>
          Add Meal for {day}
        </Typography>

        <FormControl fullWidth sx={{ mb: 2 }}>
          <Select
            value={selectedMealId}
            onChange={(e) => setSelectedMealId(e.target.value)}
            displayEmpty
          >
            <MenuItem value="" disabled>
              Select a Meal from Matches
            </MenuItem>
            {matchedMeals.map((meal) => (
              <MenuItem key={meal.id} value={meal.id}>
                {meal.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Typography variant="body2" sx={{ mb: 1 }}>
          Or add a custom meal:
        </Typography>
        <TextField
          fullWidth
          value={customMealName}
          onChange={(e) => setCustomMealName(e.target.value)}
          placeholder="Enter meal name"
          variant="outlined"
          sx={{ mb: 2 }}
        />

        <FormControl fullWidth sx={{ mb: 2 }}>
          <Select
            value={selectedTime}
            onChange={(e) => setSelectedTime(e.target.value)}
            displayEmpty
          >
            <MenuItem value="" disabled>
              Select Meal Time
            </MenuItem>
            <MenuItem value="Breakfast">Breakfast</MenuItem>
            <MenuItem value="Lunch">Lunch</MenuItem>
            <MenuItem value="Dinner">Dinner</MenuItem>
          </Select>
        </FormControl>

        <Button
          variant="contained"
          color="primary"
          onClick={handleAddMeal}
          disabled={!selectedTime || (!selectedMealId && !customMealName)}
          fullWidth
        >
          Add Meal
        </Button>
      </Box>
    </Modal>
  );
};

export default AddDirectMealModal;
