import React, { Component } from "react";
import TopBar from "../components/TopBar";
import { firebaseService } from "../services/firebaseService";
import { auth } from "../services/firebaseConfig";
import "../styles/GroceryPage.css";

class GroceryPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ingredients: [],
      checkedItems: {},
      newItemName: "",
    };
  }

  async componentDidMount() {
    try {
      const userId = auth.currentUser.uid;

      // Fetch the grocery list from Firebase
      const groceryList = await firebaseService.getGroceryList(userId);

      if (groceryList.length > 0) {
        // Set the grocery list in the state
        this.setState({ ingredients: groceryList });
        console.log("These are ingredients for GroceryPage", groceryList);
      }

      // Load checked items from local storage
      const savedCheckedItems =
        JSON.parse(localStorage.getItem("checkedItems")) || {};
      this.setState({ checkedItems: savedCheckedItems });
    } catch (error) {
      console.error("Error fetching grocery list:", error);
    }
  }

  handleCheckboxChange = (ingredientName) => {
    this.setState((prevState) => {
      const updatedCheckedItems = {
        ...prevState.checkedItems,
        [ingredientName]: !prevState.checkedItems[ingredientName],
      };

      // Save checked items to local storage
      localStorage.setItem("checkedItems", JSON.stringify(updatedCheckedItems));

      return { checkedItems: updatedCheckedItems };
    });
  };

  handleAddItem = () => {
    const { newItemName, ingredients } = this.state;

    if (newItemName.trim()) {
      const newItem = {
        id: Date.now().toString(), // Use a timestamp as a unique ID
        name: newItemName.trim(),
      };

      this.setState((prevState) => ({
        ingredients: [...prevState.ingredients, newItem],
        newItemName: "",
      }));
    }
  };

  handleNewItemChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleResetGroceryList = async () => {
    try {
      const userId = auth.currentUser.uid;

      // Reset the entire grocery list for the user
      await firebaseService.resetGroceryList(userId);

      // Clear all ingredients and checked items in the state
      this.setState({ ingredients: [], checkedItems: {} });

      // Clear local storage
      localStorage.removeItem("checkedItems");
    } catch (error) {
      console.error("Error resetting grocery list:", error);
    }
  };

  render() {
    const { ingredients, checkedItems, newItemName } = this.state;

    return (
      <div className="grocery-page">
        <TopBar title="Grocery List" />
        <div className="grocery-list-container">
          {ingredients.length === 0 ? (
            <p>No ingredients found. Please generate the grocery list.</p>
          ) : (
            <ul className="grocery-list">
              {ingredients.map((ingredient) => (
                <li key={ingredient.id} className="grocery-item">
                  <label className="grocery-item-label">
                    <input
                      type="checkbox"
                      checked={!!checkedItems[ingredient.name]}
                      onChange={() =>
                        this.handleCheckboxChange(ingredient.name)
                      }
                      className="styled-checkbox"
                    />
                    <span
                      className={
                        checkedItems[ingredient.name] ? "purchased" : ""
                      }
                    >
                      {ingredient.name}
                    </span>
                  </label>
                </li>
              ))}
            </ul>
          )}

          {/* Add item input */}
          <div className="add-item-container">
            <input
              type="text"
              name="newItemName"
              value={newItemName}
              onChange={this.handleNewItemChange}
              placeholder="Item name"
              className="add-item-input"
            />
            <button onClick={this.handleAddItem} className="add-item-button">
              Add Item
            </button>
          </div>

          {/* Reset button */}
          <div className="reset-button-container">
            <button
              onClick={this.handleResetGroceryList}
              className="reset-button"
            >
              Reset Grocery List
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default GroceryPage;
