import React from "react";
import {
  Modal,
  Box,
  Typography,
  FormControl,
  Select,
  MenuItem,
  Button,
} from "@mui/material";

const AddMealModal = ({ open, onClose, meal, onAddMeal }) => {
  const [selectedDay, setSelectedDay] = React.useState("");
  const [selectedTime, setSelectedTime] = React.useState("");

  const days = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];
  const mealTimes = ["Breakfast", "Lunch", "Dinner"];

  const handleAddMeal = () => {
    const newMeal = {
      mealId: meal.mealId,
      name: meal.name,
      day: selectedDay,
      time: selectedTime,
    };

    onAddMeal(newMeal);
    setSelectedDay("");
    setSelectedTime("");
    onClose(); // Close modal after adding
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 300,
          bgcolor: "background.paper",
          p: 4,
          borderRadius: 2,
          boxShadow: 24,
        }}
      >
        <Typography variant="h6" gutterBottom>
          Add {meal?.name} to Meal Plan
        </Typography>
        <FormControl fullWidth sx={{ mb: 2 }}>
          <Select
            value={selectedDay}
            onChange={(e) => setSelectedDay(e.target.value)}
            displayEmpty
          >
            <MenuItem value="" disabled>
              Select Day
            </MenuItem>
            {days.map((day) => (
              <MenuItem key={day} value={day}>
                {day}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl fullWidth sx={{ mb: 2 }}>
          <Select
            value={selectedTime}
            onChange={(e) => setSelectedTime(e.target.value)}
            displayEmpty
          >
            <MenuItem value="" disabled>
              Select Meal Time
            </MenuItem>
            {mealTimes.map((time) => (
              <MenuItem key={time} value={time}>
                {time}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button
          variant="contained"
          color="primary"
          onClick={handleAddMeal}
          disabled={!selectedDay || !selectedTime}
          fullWidth
        >
          Add Meal
        </Button>
      </Box>
    </Modal>
  );
};

export default AddMealModal;
