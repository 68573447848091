import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { authService } from "../services/authService"; // Adjust to named import
import InputField from "../components/InputField";
import ActionButton from "../components/ActionButton";
import "../styles/LoginPage.css";

function LoginPage() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await authService.login(email, password);
      navigate("/"); // Redirect to the swipe page after successful login
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <div className="login-page">
      <div className="nav-buttons">
        <ActionButton
          text="Login"
          onClick={() => navigate("/login")}
          isSelected={window.location.pathname === "/login"}
        />
        <ActionButton
          text="Register"
          onClick={() => navigate("/register")}
          isSelected={window.location.pathname === "/register"}
        />
      </div>
      <h1 className="login-title">Login</h1>
      <h2 className="login-subtitle">Welcome to Matchas!</h2>
      <form className="login-form" onSubmit={handleSubmit}>
        <InputField
          label="Email"
          type="email"
          placeholder="Enter your email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <InputField
          label="Password"
          type="password"
          placeholder="Enter your password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        {error && <div className="error-message">{error}</div>}
        <ActionButton text="Sign In" type="submit" />
      </form>
    </div>
  );
}

export default LoginPage;
