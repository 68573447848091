import React from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog } from "@fortawesome/free-solid-svg-icons";
import "../styles/TopBar.css"; // Import the dedicated CSS file

const TopBar = ({ title }) => {
  const navigate = useNavigate();

  return (
    <header className="top-bar">
      <div className="top-bar-content">
        <h1 className="top-bar-title">{title}</h1>
        <button
          className="settings-button"
          onClick={() => navigate("/settings")}
        >
          <FontAwesomeIcon icon={faCog} />
        </button>
      </div>
    </header>
  );
};

export default TopBar;
