import React, { createContext, useState, useEffect } from "react";
import { auth } from "../services/firebaseConfig"; // Ensure the correct path to your Firebase config
import { CircularProgress } from "@mui/material";

export const SwipeContext = createContext();

export const SwipeProvider = ({ children }) => {
  const [meals, setMeals] = useState([]);
  const [currentMealIndex, setCurrentMealIndex] = useState(0);
  const [loading, setLoading] = useState(true);
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setUserId(user.uid);
      } else {
        setUserId(null);
      }
      setLoading(false); // Set loading to false once we have the auth state
    });

    return () => unsubscribe();
  }, []);

  if (loading) {
    // Show a loading indicator while we're waiting for the auth state
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  if (!userId) {
    // Handle the case where there is no authenticated user
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <p>Please log in to continue.</p>
      </div>
    );
  }

  return (
    <SwipeContext.Provider
      value={{ meals, setMeals, currentMealIndex, setCurrentMealIndex }}
    >
      {children}
    </SwipeContext.Provider>
  );
};
