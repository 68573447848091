import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { authService } from "../services/authService"; // Ensure this import is correct
import InputField from "../components/InputField";
import ActionButton from "../components/ActionButton";
import "../styles/RegisterPage.css";

function RegisterPage() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [userName, setUserName] = useState("");
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await authService.register(email, password, userName);
      navigate("/"); // Redirect to the homepage or login page after successful registration
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <div className="register-page">
      <div className="nav-buttons">
        <ActionButton
          text="Login"
          onClick={() => navigate("/login")}
          isSelected={window.location.pathname === "/login"}
        />
        <ActionButton
          text="Register"
          onClick={() => navigate("/register")}
          isSelected={window.location.pathname === "/register"}
        />
      </div>
      <h1 className="register-title">Register</h1>
      <h2 className="register-subtitle">Join Matchas!</h2>
      <form className="register-form" onSubmit={handleSubmit}>
        <InputField
          label="Name"
          value={userName}
          onChange={(e) => setUserName(e.target.value)}
          placeholder="Enter your name"
          required
        />
        <InputField
          label="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Enter your email"
          required
        />
        <InputField
          label="Password"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Enter your password"
          required
        />
        {error && <div className="error-message">{error}</div>}
        <ActionButton type="submit" text="Sign Up" />
      </form>
    </div>
  );
}

export default RegisterPage;
