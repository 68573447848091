import React, { createContext, useContext, useState, useEffect } from "react";
import { firebaseService } from "../services/firebaseService";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../services/firebaseConfig";

const LikedMealsContext = createContext();

export const useLikedMeals = () => {
  return useContext(LikedMealsContext);
};

export const LikedMealsProvider = ({ children }) => {
  const [likedMeals, setLikedMeals] = useState([]);
  const [loading, setLoading] = useState(true);
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserId(user.uid);
        fetchLikedMeals(user.uid);
      }
    });

    return () => unsubscribe();
  }, []);

  const fetchLikedMeals = async (userId) => {
    setLoading(true);
    await firebaseService.getLikedMeals(userId, setLikedMeals);
    setLoading(false);
  };

  return (
    <LikedMealsContext.Provider
      value={{ likedMeals, setLikedMeals, loading, fetchLikedMeals }}
    >
      {children}
    </LikedMealsContext.Provider>
  );
};
