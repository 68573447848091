import React, { useState, useEffect } from "react";
import {
  Typography,
  Button,
  Box,
  FormControl,
  FormLabel,
  Slider,
  Container,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import TopBar from "../components/TopBar";
import { firebaseService } from "../services/firebaseService";
import { auth } from "../services/firebaseConfig";

const dietaryOptions = {
  categories: [
    "Accompagnement",
    "Aperos",
    "Cocktail",
    "Desserts",
    "Poisson",
    "Végétarien",
    "Viande",
  ],
  nutriScores: ["A", "B", "C", "D", "E"],
  ecoScores: ["A", "B", "C", "D", "E"],
};

const nutriScoreMarks = [
  { value: 0, label: "A" },
  { value: 1, label: "B" },
  { value: 2, label: "C" },
  { value: 3, label: "D" },
  { value: 4, label: "E" },
];

const ecoScoreMarks = [
  { value: 0, label: "A" },
  { value: 1, label: "B" },
  { value: 2, label: "C" },
  { value: 3, label: "D" },
  { value: 4, label: "E" },
];

function DietaryPreferencesPage() {
  const [user] = useAuthState(auth);
  const [preferences, setPreferences] = useState({
    categories: [],
    nutriScores: 4, // Default to "E"
    ecoScores: 4, // Default to "E"
  });

  useEffect(() => {
    if (user) {
      firebaseService.getDietaryPreferences(user.uid).then((data) => {
        setPreferences({
          categories: data.categories || [],
          nutriScores: dietaryOptions.nutriScores.indexOf(
            data.nutriScores || "E"
          ),
          ecoScores: dietaryOptions.ecoScores.indexOf(data.ecoScores || "E"),
        });
      });
    }
  }, [user]);

  const handleSavePreferences = async () => {
    if (user) {
      const preferencesToSave = {
        ...preferences,
        // Use 4 (corresponding to "E") as a fallback if `preferences.nutriScores` or `preferences.ecoScores` are undefined
        nutriScores: dietaryOptions.nutriScores[preferences.nutriScores ?? 4],
        ecoScores: dietaryOptions.ecoScores[preferences.ecoScores ?? 4],
      };

      console.log(
        "This is preferences from the handle function",
        preferencesToSave
      );

      const success = await firebaseService.saveDietaryPreferences(
        user.uid,
        preferencesToSave
      );
      if (success) {
        console.log("Preferences saved successfully");
      } else {
        console.log("Error saving preferences");
      }
    }
  };

  const handleCheckboxChange = (event, type) => {
    const { name, checked } = event.target;
    setPreferences((prevPreferences) => {
      const updatedPreferences = { ...prevPreferences };
      if (checked) {
        updatedPreferences[type].push(name);
      } else {
        updatedPreferences[type] = updatedPreferences[type].filter(
          (item) => item !== name
        );
      }
      return updatedPreferences;
    });
  };

  const handleSliderChange = (event, value, type) => {
    console.log(`Slider changed for ${type}:`, value); // Log the type and value
    setPreferences((prevPreferences) => ({
      ...prevPreferences,
      [type]: value,
    }));
  };

  const valueLabelFormat = (value, type) => dietaryOptions[type][value];

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        width: "100%",
        padding: "5px",
        bgcolor: "#f5f5f5", // White smoke
      }}
    >
      <TopBar title="Preferences" />
      <Container
        maxWidth="sm"
        sx={{
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          pt: 2,
          px: 2,
        }}
      >
        <FormControl component="fieldset" sx={{ mt: 2 }}>
          <FormLabel component="legend" sx={{ color: "black" }}>
            Categories
          </FormLabel>
          <FormGroup>
            {dietaryOptions.categories.map((category) => (
              <FormControlLabel
                key={category}
                control={
                  <Checkbox
                    checked={preferences.categories.includes(category)}
                    onChange={(e) => handleCheckboxChange(e, "categories")}
                    name={category}
                    sx={{ color: "#474973" }} // Ultra Violet
                  />
                }
                label={category}
                sx={{ color: "black" }} // Black text
              />
            ))}
          </FormGroup>
        </FormControl>

        <FormControl component="fieldset" sx={{ mt: 2 }}>
          <FormLabel component="legend" sx={{ color: "black" }}>
            Nutri-scores (Minimum Score)
          </FormLabel>
          <Typography variant="body2" sx={{ color: "black", mb: 1 }}>
            Select the minimum Nutri-score you are comfortable with. Higher
            ratings include all lower ratings.
          </Typography>
          <Slider
            value={preferences.nutriScores}
            onChange={(e, value) => handleSliderChange(e, value, "nutriScores")}
            step={1}
            marks={nutriScoreMarks}
            min={0}
            max={4}
            track="inverted"
            valueLabelDisplay="auto"
            valueLabelFormat={(value) => valueLabelFormat(value, "nutriScores")}
            sx={{
              "& .MuiSlider-track": {
                bgcolor: "#5ca135", // Green
              },
              "& .MuiSlider-rail": {
                bgcolor: "#b0b0b0", // Grey
              },
              "& .MuiSlider-thumb": {
                bgcolor: "#5ca135", // Green thumb
              },
              color: "#5ca135", // Green
            }}
          />
        </FormControl>

        <FormControl component="fieldset" sx={{ mt: 2 }}>
          <FormLabel component="legend" sx={{ color: "black" }}>
            Eco-scores (Minimum Score)
          </FormLabel>
          <Typography variant="body2" sx={{ color: "black", mb: 1 }}>
            Select the minimum Eco-score you are comfortable with. Higher
            ratings include all lower ratings.
          </Typography>
          <Slider
            value={preferences.ecoScores}
            onChange={(e, value) => handleSliderChange(e, value, "ecoScores")}
            step={1}
            marks={ecoScoreMarks}
            min={0}
            max={4}
            track="inverted"
            valueLabelDisplay="auto"
            valueLabelFormat={(value) => valueLabelFormat(value, "ecoScores")}
            sx={{
              "& .MuiSlider-track": {
                bgcolor: "#5ca135", // Green
              },
              "& .MuiSlider-rail": {
                bgcolor: "#b0b0b0", // Grey
              },
              "& .MuiSlider-thumb": {
                bgcolor: "#5ca135", // Green thumb
              },
              color: "#5ca135", // Green
            }}
          />
        </FormControl>

        <Button
          fullWidth
          variant="contained"
          sx={{
            bgcolor: "#f45f67", // Bright pink (Crayola)
            color: "#f5f5f5", // White smoke
            borderRadius: 25,
            mt: 3,
            "&:hover": {
              bgcolor: "#ef1e28", // Darker pink
            },
          }}
          onClick={handleSavePreferences}
        >
          Save Preferences
        </Button>
      </Container>
    </Box>
  );
}

export default DietaryPreferencesPage;
