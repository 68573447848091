import React from "react";
import "../styles/ActionButton.css";

function ActionButton({ text, onClick, type = "button", isSelected }) {
  return (
    <button
      className={`action-button ${isSelected ? "selected" : ""}`}
      onClick={onClick}
      type={type}
    >
      {text}
    </button>
  );
}

export default ActionButton;
