import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyCSjSJTUUW5ULsQ5C-FruOwogSzFsyM5iY",
  authDomain: "mealswipe-de88c.firebaseapp.com",
  databaseURL:
    "https://mealswipe-de88c-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "mealswipe-de88c",
  storageBucket: "mealswipe-de88c.appspot.com",
  messagingSenderId: "720317606661",
  appId: "1:720317606661:web:d35787e3a2227850ace56c",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getDatabase(app);
const auth = getAuth(app);

export { db, auth };
