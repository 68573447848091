import React from "react";
import "../styles/MatchNotification.css"; // Import your custom CSS

const MatchNotification = ({ open, onClose, meal, message }) => {
  if (!open || !meal || !meal.img_link) {
    // If not open or meal data is missing, don't render anything
    return null;
  }

  return (
    <div className="match-notification">
      <div className="match-content">
        <img src={meal.img_link} alt={meal.title} className="match-image" />
        <div className="match-text-container">
          <h4 className="match-title">🎉 It's a Match! 🎉</h4>
          <p className="match-message">
            You and your mate loved <strong>{meal.title}</strong>!
          </p>
        </div>
        <button className="close-button" onClick={onClose}>
          &times;
        </button>
      </div>
    </div>
  );
};

export default MatchNotification;
