import React, { createContext, useState, useContext, useEffect } from "react";
import { firebaseService } from "../services/firebaseService";
import { auth } from "../services/firebaseConfig";

const MatchesContext = createContext();

export const useMatches = () => {
  return useContext(MatchesContext);
};

export const MatchesProvider = ({ children }) => {
  const [superMatches, setSuperMatches] = useState([]);
  const [regularMatches, setRegularMatches] = useState([]);

  useEffect(() => {
    const fetchMatches = async () => {
      try {
        const userId = auth.currentUser?.uid; // Assuming auth is available and currentUser is logged in
        await firebaseService.getMatches(
          setSuperMatches,
          setRegularMatches,
          userId
        );
      } catch (error) {
        console.error("Error fetching matches:", error);
      }
    };

    fetchMatches();
  }, []);

  return (
    <MatchesContext.Provider value={{ superMatches, regularMatches }}>
      {children}
    </MatchesContext.Provider>
  );
};
