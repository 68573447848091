import React from "react";
import { Grid, IconButton } from "@mui/material";
import { ReactComponent as CloseIcon } from "../assets/close-icon.svg";
import { ReactComponent as QuestionMarkIcon } from "../assets/question-mark-icon.svg";
import { ReactComponent as FavoriteIcon } from "../assets/favorite-icon.svg";
import { ReactComponent as StarIcon } from "../assets/star-icon.svg";
import "../styles/RatingButtons.css";

const RatingButtons = ({ handleRating }) => {
  return (
    <Grid container className="rating-buttons">
      <IconButton
        onClick={() => handleRating("Dislike")}
        className="rating-button dislike-button"
      >
        <CloseIcon className="custom-icon" />
      </IconButton>
      <IconButton
        onClick={() => handleRating("Why not")}
        className="rating-button why-not-button"
      >
        <QuestionMarkIcon className="custom-icon" />
      </IconButton>
      <IconButton
        onClick={() => handleRating("Like")}
        className="rating-button like-button"
      >
        <FavoriteIcon className="custom-icon" />
      </IconButton>
      <IconButton
        onClick={() => handleRating("Best")}
        className="rating-button best-button"
      >
        <StarIcon className="custom-icon" />
      </IconButton>
    </Grid>
  );
};

export default RatingButtons;
