import React, { useState, useEffect } from "react";
import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  IconButton,
  CircularProgress,
  Box,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import { firebaseService } from "../services/firebaseService";

const SwipeCard = ({
  mealId,
  ratingType,
  getLikeTypeIcon,
  onUnlike,
  showUnlikeButton,
  onRatingClick,
}) => {
  const [meal, setMeal] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchMeal = async () => {
      try {
        setLoading(true);
        const mealData = await firebaseService.getMealById(mealId);
        setMeal(mealData);
      } catch (error) {
        console.error("Error fetching meal:", error);
      } finally {
        setLoading(false);
      }
    };

    if (mealId) {
      fetchMeal();
    }
  }, [mealId]);

  const handleCardClick = () => {
    if (meal && meal.id) {
      navigate(`/meal/${meal.id}`, { state: { meal } });
    }
  };

  if (loading) {
    return <CircularProgress />;
  }

  if (!meal) {
    return <Typography>No meal data found</Typography>;
  }

  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        borderRadius: 2,
        bgcolor: "#f5f5f5",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        position: "relative",
      }}
      onClick={handleCardClick}
    >
      <Box sx={{ position: "relative" }}>
        {meal.img_link && (
          <CardMedia
            component="img"
            height="140"
            image={meal.img_link}
            alt={meal.title}
            sx={{ objectFit: "cover" }}
          />
        )}
        <Box
          sx={{
            position: "absolute",
            top: 8,
            left: 8,
            bgcolor: "#FFAAAA",
            borderRadius: "50%",
            padding: 0.5,
          }}
        >
          <IconButton onClick={onRatingClick} size="small" sx={{ padding: 0 }}>
            {getLikeTypeIcon(ratingType)}
          </IconButton>
        </Box>
        {showUnlikeButton && (
          <Box
            sx={{
              position: "absolute",
              top: 8,
              right: 8,
              bgcolor: "rgba(255, 255, 255, 0.7)",
              borderRadius: "50%",
              padding: 0.5,
            }}
          >
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                onUnlike(mealId);
              }}
              size="small"
              color="error"
              sx={{ padding: 0 }}
            >
              <DeleteIcon />
            </IconButton>
          </Box>
        )}
      </Box>

      <CardContent
        sx={{ flexGrow: 1, display: "flex", flexDirection: "column" }}
      >
        <Typography
          variant="h6"
          component="div"
          gutterBottom
          sx={{
            fontSize: 14,
            fontWeight: "bold",
            color: "#474973",
          }}
        >
          {meal.title || "No title available"}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default SwipeCard;
