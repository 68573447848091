import React, { useState, useEffect } from "react";
import {
  Typography,
  List,
  ListItem,
  ListItemText,
  Button,
  Box,
  TextField,
  Grid,
  Divider,
  Container,
} from "@mui/material";
import { auth, db } from "../services/firebaseConfig";
import { ref, get, update, push, set } from "firebase/database";
import { useAuthState } from "react-firebase-hooks/auth";
import { firebaseService } from "../services/firebaseService";
import TopBar from "../components/TopBar";
import "../styles/PairingManagementPage.css"; // Import the CSS file

function PairingManagementPage() {
  const [user] = useAuthState(auth);
  const [pairedUsers, setPairedUsers] = useState([]);
  const [pendingRequests, setPendingRequests] = useState([]);
  const [pairingCode, setPairingCode] = useState("");
  const [userPairingCode, setUserPairingCode] = useState("");

  useEffect(() => {
    if (user) {
      const userRef = ref(db, `users/${user.uid}`);
      get(userRef).then((snapshot) => {
        if (snapshot.exists()) {
          const data = snapshot.val();
          setPairedUsers(data.pairedUserIds || []);
          setPendingRequests(data.pairingRequests || []);
          setUserPairingCode(data.pairingCode || "");
        }
      });
    }
  }, [user]);

  const generatePairingCode = () => {
    const code = Math.random().toString(36).substring(2, 8).toUpperCase();
    update(ref(db, `users/${user.uid}`), { pairingCode: code });
    setUserPairingCode(code);
  };

  const handleSendPairingRequest = async () => {
    // Reference to the users node
    const usersRef = ref(db, "users");
    try {
      // Get all users
      const snapshot = await get(usersRef);
      if (snapshot.exists()) {
        const users = snapshot.val();
        let targetUserId = null;

        // Iterate through users to find the matching pairing code
        Object.keys(users).forEach((uid) => {
          const userData = users[uid];
          if (userData.pairingCode === pairingCode) {
            targetUserId = uid;
          }
        });

        if (targetUserId) {
          // Proceed with sending the pairing request
          const targetUserRef = ref(db, `users/${targetUserId}`);
          const targetUserSnapshot = await get(targetUserRef);
          if (targetUserSnapshot.exists()) {
            const targetUserData = targetUserSnapshot.val();
            const newPairingRequests = targetUserData.pairingRequests
              ? [...targetUserData.pairingRequests, user.uid]
              : [user.uid];
            await update(targetUserRef, {
              pairingRequests: newPairingRequests,
            });
            alert("Pairing request sent.");
            setPairingCode("");
          }
        } else {
          alert("Invalid pairing code.");
        }
      }
    } catch (error) {
      console.error("Error fetching users or sending pairing request:", error);
    }
  };

  const handleAcceptRequest = async (requesterId) => {
    const userRef = ref(db, `users/${user.uid}`);
    const userSnapshot = await get(userRef);
    if (!userSnapshot.exists()) return;
    const userData = userSnapshot.val();

    const requesterRef = ref(db, `users/${requesterId}`);
    const requesterSnapshot = await get(requesterRef);
    if (!requesterSnapshot.exists()) return;
    const requesterData = requesterSnapshot.val();

    // Add each other to their paired user lists
    const updatedPairedUserIds = [
      ...(userData.pairedUserIds || []),
      requesterId,
    ];
    const updatedRequesterPairedUserIds = [
      ...(requesterData.pairedUserIds || []),
      user.uid,
    ];

    // Update both users' records
    await update(userRef, {
      pairedUserIds: updatedPairedUserIds,
      pairingRequests: userData.pairingRequests.filter(
        (id) => id !== requesterId
      ),
    });
    await update(requesterRef, {
      pairedUserIds: updatedRequesterPairedUserIds,
    });

    setPairedUsers(updatedPairedUserIds);
    setPendingRequests(
      userData.pairingRequests.filter((id) => id !== requesterId)
    );

    // Check for existing likes/swipes for potential matches
    const userSwipesRef = ref(db, `swipes/${user.uid}`);
    const requesterSwipesRef = ref(db, `swipes/${requesterId}`);

    const [userSwipesSnapshot, requesterSwipesSnapshot] = await Promise.all([
      get(userSwipesRef),
      get(requesterSwipesRef),
    ]);

    if (userSwipesSnapshot.exists() && requesterSwipesSnapshot.exists()) {
      const userSwipes = userSwipesSnapshot.val();
      const requesterSwipes = requesterSwipesSnapshot.val();

      // Compare swipes between users for each mealId
      Object.keys(userSwipes).forEach(async (userSwipeKey) => {
        const userSwipe = userSwipes[userSwipeKey];

        Object.keys(requesterSwipes).forEach(async (requesterSwipeKey) => {
          const requesterSwipe = requesterSwipes[requesterSwipeKey];

          if (
            userSwipe.mealId === requesterSwipe.mealId &&
            userSwipe.ratingType !== "Dislike" &&
            requesterSwipe.ratingType !== "Dislike"
          ) {
            // Determine if it's a supermatch or normal match
            const isSuperMatch =
              userSwipe.ratingType === requesterSwipe.ratingType;

            // Fetch meal details for the match
            const mealRef = ref(db, `meals/${userSwipe.mealId}`);
            const mealSnapshot = await get(mealRef);
            if (mealSnapshot.exists()) {
              const mealData = mealSnapshot.val();

              // Create a match entry
              const matchesRef = ref(db, "matches");
              const newMatchRef = push(matchesRef);
              await set(newMatchRef, {
                mealId: userSwipe.mealId,
                users: [user.uid, requesterId],
                name: mealData.title,
                imageUrl: mealData.img_link,
                timestamp: Date.now(),
                ratings: {
                  [user.uid]: userSwipe.ratingType,
                  [requesterId]: requesterSwipe.ratingType,
                },
                isSuperMatch: isSuperMatch,
              });
              console.log(
                `Match recorded: ${
                  isSuperMatch ? "Supermatch" : "Normal match"
                }`,
                {
                  mealId: userSwipe.mealId,
                  users: [user.uid, requesterId],
                  ratingType: userSwipe.ratingType,
                }
              );
            }
          }
        });
      });
    }
  };

  const handleDeclineRequest = async (requesterId) => {
    const userRef = ref(db, `users/${user.uid}`);
    const userSnapshot = await get(userRef);
    const userData = userSnapshot.val();

    await update(userRef, {
      pairingRequests: userData.pairingRequests.filter(
        (id) => id !== requesterId
      ),
    });

    setPendingRequests(
      userData.pairingRequests.filter((id) => id !== requesterId)
    );
  };

  const handleRemovePairing = async (pairedUserId) => {
    const userRef = ref(db, `users/${user.uid}`);
    const userSnapshot = await get(userRef);
    const userData = userSnapshot.val();

    const pairedUserRef = ref(db, `users/${pairedUserId}`);
    const pairedUserSnapshot = await get(pairedUserRef);
    const pairedUserData = pairedUserSnapshot.val();

    const updatedPairedUserIds = userData.pairedUserIds.filter(
      (id) => id !== pairedUserId
    );
    const updatedPairedUserPairedUserIds = pairedUserData.pairedUserIds.filter(
      (id) => id !== user.uid
    );

    await update(userRef, { pairedUserIds: updatedPairedUserIds });
    await update(pairedUserRef, {
      pairedUserIds: updatedPairedUserPairedUserIds,
    });

    setPairedUsers(updatedPairedUserIds);
  };

  return (
    <div className="pairing-management-page">
      <TopBar title="Pairing" />
      <Container maxWidth="md" className="pairing-container">
        <Divider sx={{ my: 2 }} />
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h6" align="center" className="pairing-title">
              Your Pairing Code
            </Typography>
            <Typography
              variant="h4"
              align="center"
              className="pairing-title"
              sx={{ my: 2 }}
            >
              {userPairingCode || "Not Set"}
            </Typography>
            <Button
              variant="contained"
              color="primary"
              className="pairing-button"
              onClick={generatePairingCode}
            >
              Generate New Pairing Code
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h4" align="center" className="pairing-title">
              Enter pairing code
            </Typography>
            <TextField
              fullWidth
              label="Enter Pairing Code"
              value={pairingCode}
              onChange={(e) => setPairingCode(e.target.value)}
              className="pairing-input"
            />
            <Button
              fullWidth
              variant="contained"
              className="pairing-button"
              onClick={handleSendPairingRequest}
            >
              Send Pairing Request
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" className="pairing-title">
              Pending Pairing Requests
            </Typography>
            <List className="pairing-list">
              {pendingRequests.map((requesterId) => (
                <ListItem key={requesterId} className="list-item">
                  <ListItemText primary={`Request from: ${requesterId}`} />
                  <Button
                    variant="outlined"
                    className="list-button"
                    onClick={() => handleAcceptRequest(requesterId)}
                    color="primary"
                  >
                    Accept
                  </Button>
                  <Button
                    variant="outlined"
                    className="list-button"
                    onClick={() => handleDeclineRequest(requesterId)}
                    color="secondary"
                  >
                    Decline
                  </Button>
                </ListItem>
              ))}
            </List>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" className="pairing-title">
              Current Pairings
            </Typography>
            <List className="pairing-list">
              {pairedUsers.map((pairedUserId) => (
                <ListItem key={pairedUserId} className="list-item">
                  <ListItemText primary={`Paired with: ${pairedUserId}`} />
                  <Button
                    variant="outlined"
                    className="list-button"
                    onClick={() => handleRemovePairing(pairedUserId)}
                    color="error"
                  >
                    Remove
                  </Button>
                </ListItem>
              ))}
            </List>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default PairingManagementPage;
